import InputMask from "react-input-mask";

const PhoneNumber = (props: any) => {
  const phoneNumberValidation = () => {
    const regex = props.country === "UK" ? /^44[1-9]{1}\d{8,9}$/ : /^1\d{10}$/;
    return regex.test(props.phoneNumber);
  };
  return (
    <div>
      <p>Finally, what phone number should the Thinking Machine call you at?</p>
      <InputMask
        mask={props.country === "UK" ? "+44 999 999 9999" : "+1 (999) 999-9999"}
        maskChar={"_"}
        onChange={props.onChange}
      >
        <div className={"inputContainer"}>
          <select id="countrySelect" name="country" onChange={props.onChange}>
            <option selected>US/CA</option>
            <option>UK</option>
          </select>
          <input
            type="tel"
            placeholder="Mobile Phone Number"
            id="phoneNumber"
            name="phoneNumber"
            onChange={props.onChange}
            onKeyDown={(e) => {
              props.handleEnter(e, phoneNumberValidation, props.nextStep);
            }}
            required
            disabled={props.successfulFormSubmit}
          />
          {!!props.errors["phoneNumber"] &&
            !!props.submitAttempted["phoneNumber"] && (
              <div className="tooltip">{props.errorMessage}</div>
            )}
        </div>
      </InputMask>

      <button
        id="phoneNumberButton"
        onClick={(e) => {
          e.preventDefault();
          props.nextStepCheck(e, phoneNumberValidation, props.nextStep);
        }}
      >
        {props.successfulFormSubmit ? "Starting..." : "Submit"}
      </button>
      <p id="disclaimerText">
        {
          "*This demo only support US, CA and UK numbers at this time.\nBy clicking submit, you agree to the terms of Replicant's "
        }
        <span>
          <a href="https://www.replicant.com/legal/privacy-policy/">
            Privacy Policy.
          </a>
        </span>
      </p>
    </div>
  );
};

export default PhoneNumber;
