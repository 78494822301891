import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { baseUrl } from "../consts/baseUrl";
import { SessionData, TranscriptTurn } from "../types/interfaces";
import { generateToken } from "../helpers/helpers";
import { callProgressMarkers } from "../consts/callProgress";

export default function useSessionData() {
  // ###############################################################################################################################
  //  Definition of session state object
  // ###############################################################################################################################

  const [sessionData, setSessionData] = useState<SessionData>({
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    // Preset phone number to US/CA for input masking on form
    phoneNumber: "US/CA",
    demoType: "EMR",
    demoTypePreSelected: false,
    session: {
      sessionCreated: false,
      experienceStarted: false,
      progressBar: 0,
      experienceLoaded: false,
      sessionId: "",
      sessionValid: false,
      isMultipleSessions: false,
      formLoadCounter: 1,
      refreshedDuringCall: false,
    },
    call: {
      callStarted: false,
      callTime: 0,
      callProgress: 0,
      callComplete: false,
      currentTurn: 0,
      boothId: "",
      fullTranscript: [],
      internalTestCall: false,
      isRateLimitExceeded: false,
    },
  });

  // Destructuring state objects used in hook
  const {
    demoType,
    demoTypePreSelected,
    firstName,
    lastName,
    email,
    country,
    phoneNumber,
  } = sessionData;
  const {
    sessionCreated,
    experienceStarted,
    experienceLoaded,
    sessionId,
    progressBar,
    refreshedDuringCall,
  } = sessionData.session;
  const {
    callStarted,
    callTime,
    callComplete,
    currentTurn,

    boothId,
    internalTestCall,
  } = sessionData.call;

  // ###############################################################################################################################
  // Check session status on site load
  // ###############################################################################################################################

  useEffect(() => {
    // Function to check session status
    // TODO: Make use of isComplete to display call data if page refreshes
    const checkSession = async (sessionId: string): Promise<void> => {
      const config = {
        method: "get",
        url: `${baseUrl}/session/${sessionId}/status`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_BACKEND_SERVICE_KEY}`,
        },
      };
      // Make the API call
      await axios(config)
        .then((response) => {
          const sessionValid = response.data.isValid;
          const callComplete = response.data.isComplete;
          // If session is still valid, update newSession in sessionData state
          if (sessionValid) {
            if (
              (window.location.href.includes("/ers") ||
                window.location.href.includes("/scheduling")) &&
              callComplete
            ) {
              localStorage.clear();
              return;
            }
            setSessionData((prev): SessionData => {
              return {
                ...prev,
                // Setting callStarted + experienceLoaded to true will fetch transcript data if callComplete is false
                call: { ...prev.call, callStarted: true, callComplete },
                session: {
                  ...prev.session,
                  sessionId,
                  experienceLoaded: true,
                  sessionValid,
                  refreshedDuringCall: !callComplete ? true : false,
                },
              };
            });
            if (!callComplete) {
            }
          }
        })
        .catch((error) => {
          console.log(error);
          // If there's a GONE/NOT FOUND error, session is invalid + clear local storage
          if (error.response.status === 410) {
            localStorage.clear();
          }
        });
    };

    // Grab the URL to check if we are in booth 1 or booth 2
    const url = window.location.href;
    if (url.includes("booth-1") || url.includes("booth-2")) {
      // Set boothId in state to 1 or 2 depending on URL
      url.includes("booth-1")
        ? setSessionData((prev) => {
            return { ...prev, call: { ...prev.call, boothId: "1" } };
          })
        : setSessionData((prev) => {
            return { ...prev, call: { ...prev.call, boothId: "2" } };
          });
      // Wipe the session ID on page load when we are in booths
      localStorage.sessionId = "";
      // Set internalTestCall to true if we are on internal testing page
    }
    if (url.includes("/internal-testing")) {
      setSessionData((prev) => {
        return { ...prev, call: { ...prev.call, internalTestCall: true } };
      });
    }
    if (url.includes("/ers")) {
      setSessionData((prev) => {
        return { ...prev, demoType: "ERS", demoTypePreSelected: true };
      });
    } else if (url.includes("/scheduling")) {
      // Don't need to set demoType since EMR is default
      setSessionData((prev) => {
        return { ...prev, demoTypePreSelected: true };
      });
    }

    if (localStorage.sessionId) {
      // Hit API endpoint that checks if session is valid if session ID exists
      // State will be updated if session is still valid
      checkSession(localStorage.sessionId).catch(console.error);
    }
  }, []);

  // ###############################################################################################################################
  // Function to create session
  // ###############################################################################################################################

  // TODO: Add error handling
  const createSession = async (
    name: string,
    email: string,
    phoneNumber: string,
    demoType: "EMR" | "ERS"
  ): Promise<{
    sessionId: string;
    country: string;
  }> => {
    const data = JSON.stringify({
      name,
      email,
      phoneNumber,
      demoType,
    });
    const config = {
      method: "post",
      url: `${baseUrl}/session/new`,
      withCredentials: false,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_BACKEND_SERVICE_KEY}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const result = await axios(config)
      .then((response) => {
        // If session ID returned, update state and add session ID to local storage
        if (response.data.sessionId) {
          setSessionData((prev): SessionData => {
            return {
              ...prev,
              // If we're coming from post call page there won't be a name in state, so we need to split the name from the response
              firstName: !!firstName ? firstName : name.split(" ")[0],
              lastName: !!lastName ? lastName : name.split(" ")[1],
              email,
              country: response.data.country,
              phoneNumber,
              session: {
                ...prev.session,
                sessionCreated: true,
                sessionId: response.data.sessionId,
                experienceLoaded: false,
              },
              call: {
                ...prev.call,
              },
            };
          });
          localStorage.sessionId = response.data.sessionId;
          // If not, update state to reflect with field was invalid
        } else {
          // TODO: Add handling if no session ID is returned
        }
        return response.data;
      })
      .catch(console.error);
    return result;
  };

  // ###############################################################################################################################
  // Hook to start call when experience starts
  // ###############################################################################################################################

  useEffect(() => {
    // FUNCTION TO CREATE OUTBOUND CALL
    const createCall = async (
      name: string,
      email: string,
      country: string,
      phoneNumber: string,
      boothId: string,
      internalTestCall: boolean
    ): Promise<boolean | void> => {
      const data = JSON.stringify({
        name,
        email,
        country,
        phoneNumber,
        id: boothId,
        internalTestCall,
      });
      // Generate the JWT token
      const token = await generateToken(sessionId);
      const config = {
        method: "post",
        url: `${baseUrl}/session/${sessionId}/start-call`,
        withCredentials: false,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data,
      };
      const result = axios(config)
        .then(() => {
          // Update callStarted/callComplete in state if either are true (will happen in the case of starting call from post-call page)
          if (callStarted || callComplete) {
            setSessionData((prev): SessionData => {
              return {
                ...prev,
                call: {
                  ...prev.call,
                  callStarted: false,
                  callComplete: false,
                },
              };
            });
          }
          // Update experienceStarted and sessionValid in state to true regardless of where we're coming from
          setSessionData((prev): SessionData => {
            return {
              ...prev,
              session: {
                ...prev.session,
                sessionValid: true,
                experienceStarted: true,
              },
            };
          });
          return true;
        })
        .catch((error) => {
          // If we get a 429, that means the campaign rate limit has been exceeded
          if (error.response.status === 429) {
            setSessionData((prev): SessionData => {
              return {
                ...prev,
                call: {
                  ...prev.call,
                  isRateLimitExceeded: true,
                },
              };
            });
          }
        });
      return result;
    };

    let name = `${firstName} ${lastName}`;
    // Create call if the session has been created
    if (sessionCreated) {
      createCall(name, email, country, phoneNumber, boothId, internalTestCall);
    }
  }, [sessionCreated]);

  // ###############################################################################################################################
  // 15-second timeout to set experience loaded value to true
  // ###############################################################################################################################

  const experienceLoadedRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (experienceStarted && !callStarted) {
      experienceLoadedRef.current = setTimeout(() => {
        setSessionData((prev): SessionData => {
          return {
            ...prev,
            session: { ...prev.session, experienceLoaded: true },
          };
        });
      }, 15000);
    } else {
      clearTimeout(experienceLoadedRef.current);
    }

    return () => clearTimeout(experienceLoadedRef.current);
  }, [experienceStarted, callStarted, experienceLoaded]);

  // ###############################################################################################################################
  // Interval to load progress bar graphic
  // ###############################################################################################################################

  useEffect(() => {
    if (experienceStarted) {
      const progressInterval = setInterval(() => {
        if (progressBar > 100 || callStarted) {
          clearInterval(progressInterval);
          return;
        }
        setSessionData((prev): SessionData => {
          return {
            ...prev,
            session: {
              ...prev.session,
              progressBar: prev.session.progressBar + 6 + 2 / 3,
            },
          };
        });
      }, 1000);

      return () => clearInterval(progressInterval);
    }
  }, [experienceStarted, progressBar, callStarted]);

  // ###############################################################################################################################
  // Timeout and interval to check if call has started before progress bar has loaded
  // ###############################################################################################################################

  const callStartCheckStartRef = useRef<ReturnType<typeof setTimeout>>();
  const callStartIntervalRef = useRef<ReturnType<typeof setInterval>>();

  useEffect(() => {
    // Function to check if callStarted flag in transcript data has been set to true
    const checkCallStatus = async (): Promise<void> => {
      const config = {
        method: "get",
        url: `${baseUrl}/session/${sessionId}/transcripts`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_BACKEND_SERVICE_KEY}`,
        },
      };
      const result = await axios(config).then((response) => {
        // If the isStarted flag is in the data, update callStarted in state
        if (response.data.isStarted) {
          setSessionData((prev): SessionData => {
            return {
              ...prev,
              call: {
                ...prev.call,
                callStarted: true,
              },
              session: {
                ...prev.session,
                experienceLoaded: true,
              },
            };
          });
        } else {
          return;
        }
      });
    };

    // 10 seconds after the experience starts, check if the call has started until the experience is fully loaded
    if (experienceStarted && !callStarted) {
      callStartCheckStartRef.current = setTimeout(() => {
        callStartIntervalRef.current = setInterval(() => {
          if (experienceLoaded || callStarted) {
            clearInterval(callStartIntervalRef.current);
            return;
          }

          if (!experienceLoaded) checkCallStatus();
        }, 1000);
        return () => clearInterval(callStartIntervalRef.current);
      }, 10000);
    }
    return () => {
      clearTimeout(callStartCheckStartRef.current);
      clearInterval(callStartIntervalRef.current);
    };
  }, [experienceStarted, experienceLoaded, callStarted]);

  // ###############################################################################################################################
  // Interval to get latest transcript once every half second while the call is active
  // ###############################################################################################################################

  useEffect(() => {
    // FUNCTION TO GET TRANSCRIPT
    const getLatestTranscript = async (currentTurn: number): Promise<void> => {
      const config = {
        method: "get",
        url: `${baseUrl}/session/${sessionId}/transcripts`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_BACKEND_SERVICE_KEY}`,
        },
      };
      const result = await axios(config)
        .then((response) => {
          if (!response.data.isComplete) {
            // If the isStarted flag is in the data, update callStarted in state
            if (response.data.isStarted && !sessionData.call.callStarted) {
              setSessionData((prev): SessionData => {
                return {
                  ...prev,
                  call: {
                    ...prev.call,
                    callStarted: true,
                  },
                };
              });
            }
            if (response.data.data.length > 0) {
              const fullTranscript: TranscriptTurn[] = response.data.data;
              const latestTurn: TranscriptTurn =
                response.data.data[response.data.data.length - 1];
              const turnNumber: number = latestTurn.Number;
              // Set the inital call progress bar value
              let callProgress: number = 0;
              // Filter out the action transcripts
              const actionTranscripts: string[] = fullTranscript
                .filter((turn) => {
                  return turn.type === "action";
                })
                .map((turn) => turn.transcript!);
              // Check if the action transcripts include call progress milestones
              // Update call progress bar value depending on what milestones are in action transcripts
              switch (true) {
                case actionTranscripts.some((x) =>
                  x.includes(
                    callProgressMarkers.actionTranscripts[demoType][100]
                  )
                ):
                  callProgress = 100;
                  break;
                case actionTranscripts.some((x) =>
                  x.includes(
                    callProgressMarkers.actionTranscripts[demoType][80]
                  )
                ):
                  callProgress = 80;
                  break;
                case actionTranscripts.some((x) =>
                  x.includes(
                    callProgressMarkers.actionTranscripts[demoType][60]
                  )
                ):
                  callProgress = 60;
                  break;
                case actionTranscripts.some((x) =>
                  x.includes(
                    callProgressMarkers.actionTranscripts[demoType][40]
                  )
                ):
                  callProgress = 40;
                  break;
                case actionTranscripts.some((x) =>
                  x.includes(
                    callProgressMarkers.actionTranscripts[demoType][20]
                  )
                ):
                  callProgress = 20;
                  break;
              }

              // Update the full transcript in state if the transcript grabbed from Airtable is more current
              if (turnNumber > currentTurn) {
                setSessionData((prev): SessionData => {
                  return {
                    ...prev,
                    call: {
                      ...prev.call,
                      currentTurn: turnNumber,
                      fullTranscript,
                      callProgress,
                    },
                  };
                });
              } else {
                return;
              }
            }
            // Set the call to complete in state if Airtable data shows it's complete
          } else if (response.data.isComplete) {
            setSessionData((prev): SessionData => {
              return { ...prev, call: { ...prev.call, callComplete: true } };
            });
          }
        })
        .catch(console.error);
    };
    // Start checking the transcript once the experience is loaded or the call has started
    if (experienceLoaded || callStarted) {
      const interval = setInterval(() => {
        // Stop checking once the call is complete
        if (callComplete) {
          clearInterval(interval);
          return;
        }
        getLatestTranscript(currentTurn);
      }, 500);
      return () => {
        clearInterval(interval);
      };
    }
  }, [experienceLoaded, callStarted, callComplete, currentTurn]);

  // ###############################################################################################################################
  // Interval to iterate call time timer
  // ###############################################################################################################################

  useEffect(() => {
    // Start timer once call has started
    if (callStarted) {
      const countdownInterval = setInterval(() => {
        // Stop timer when call is complete
        if (callComplete) {
          clearInterval(countdownInterval);
          return;
        }
        // Iterate timer by 1 once a second
        return setSessionData((prev): SessionData => {
          return {
            ...prev,
            call: {
              ...prev.call,
              callTime: prev.call.callTime + 1,
            },
          };
        });
      }, 1000);
      return () => {
        clearInterval(countdownInterval);
      };
    }
  }, [callStarted, callTime, callComplete]);

  // ###############################################################################################################################
  // Function to fetch full call data
  // ###############################################################################################################################

  const getFullData = async () => {
    const config = {
      method: "get",
      url: `${baseUrl}/session/${sessionId}/fullData`,
      withCredentials: false,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_BACKEND_SERVICE_KEY}`,
        "Content-Type": "application/json",
      },
    };

    const result = await axios(config).then((response) => {
      if (response.data.message.userData) {
        const { userData } = response.data.message;
        // Set the name email number and country in state to the response data received
        setSessionData((prev): SessionData => {
          return {
            ...prev,
            firstName: userData.name.split(" ")[0],
            lastName: userData.name.split(" ")[1],
            email: userData.email,
            phoneNumber: userData.phoneNumber,
            country: userData.country,
          };
        });
        return userData;
      }
    });
    // Return the data
    return result;
  };

  // ###############################################################################################################################
  //  Function to check whether a session already exists for the submitted phone number
  // ###############################################################################################################################

  const checkMultipleSessions = async (
    phoneNumber: string
  ): Promise<boolean> => {
    const config = {
      method: "get",
      url: `${baseUrl}/session/${phoneNumber}/check-sessions`,
      withCredentials: false,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_BACKEND_SERVICE_KEY}`,
        "Content-Type": "application/json",
      },
    };
    const isMultipleSessions = await axios(config)
      .then((response) => {
        // Update multipleSessions property in state if multiple sessions exist
        if (response.data.multipleSessions) {
          setSessionData((prev) => {
            return {
              ...prev,
              session: {
                ...prev.session,
                isMultipleSessions: true,
              },
            };
          });
        }
        // Return multipleSessions boolean from endpoint
        return response.data.multipleSessions;
      })
      .catch(console.error);

    return isMultipleSessions;
  };
  // ###############################################################################################################################
  //  Function to create new session after checking if there are multiple sessions active
  // ###############################################################################################################################

  const startNewSession = async (
    name?: string,
    email?: string,
    phoneNumber?: string
  ): Promise<void> => {
    // If a session ID exists, we're starting from the post-call page and need to fetch the name, email and phone number
    if (sessionId) {
      // Fetch full data using session ID in local storage
      // TODO: This will need to return demo type here as well so people can repeat same use case
      const userData = await getFullData();
      name = userData.name;
      email = userData.email;
      phoneNumber = userData.phoneNumber;

      // If the call is starting from the post-call page, the user wants the same demo type as last time
      // Unless demoType is pre-selected, set the demo type in state to the demo type from the last session
      if (!demoTypePreSelected)
        setSessionData((prev) => {
          return {
            ...prev,
            demoType: userData.demoType,
          };
        });
    }
    // Check if another session is currently active with the phone number retrieved
    const isMultipleSessions = await checkMultipleSessions(phoneNumber!);
    // If not, create a new session
    if (!isMultipleSessions) {
      setSessionData((prev) => {
        return {
          ...prev,
          session: {
            ...prev.session,
            // Reset progressBar to 0
            progressBar: 0,
            // Set session created to false so useEffect to createCall gets triggered
            sessionCreated: false,
          },
          call: {
            ...prev.call,
            // Reset callTime and callProgress
            callTime: 0,
            callProgress: 0,
            // Reset to empty transcript
            fullTranscript: [],
          },
        };
      });
      // If we have a first name and last name in state, use those, if not use the name from getFullData
      name = !!firstName && !!lastName ? `${firstName} ${lastName}` : name;
      await createSession(name!, email!, phoneNumber!, demoType!);
    }
  };

  return {
    sessionData,
    setSessionData,
    startNewSession,
  };
}
