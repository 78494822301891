import Form from "../components/Form/Form";
import "./PreCallForm.css";
import Footer from "../components/Footer";

export default function PreCallForm(props: {
  view: string;
  startNewSession: any;
  boothId?: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  country: string;
  demoType: string;
  demoTypePreSelected: boolean;
  formLoadCounter: number;
  setSessionData: any;
}) {
  const {
    view,
    startNewSession,
    boothId,
    firstName,
    lastName,
    email,
    phoneNumber,
    country,
    demoType,
    demoTypePreSelected,
    formLoadCounter,
    setSessionData,
  } = props;
  return (
    <>
      <div id="formContainer">
        <a href="https://www.replicant.com">
          <svg
            id="formLogo"
            viewBox="0 0 1021 1020"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M721.556 239.371V0H475.458C459.349 0 443.897 6.41073 432.518 17.7893L17.7906 432.518C6.41201 443.897 0 459.348 0 475.457V719.847H239.371V375.337C239.371 300.232 300.259 239.371 375.337 239.371H721.556Z"
              fill="white"
            />
            <path
              d="M781.629 299.471V643.981C781.629 719.085 720.742 779.947 645.664 779.947H299.444V1019.32H545.542C561.651 1019.32 577.103 1012.91 588.482 1001.53L1003.21 586.8C1014.59 575.421 1021 559.97 1021 543.861V299.471H781.629Z"
              fill="white"
            />
          </svg>
        </a>
        <Form
          firstName={firstName}
          lastName={lastName}
          email={email}
          phoneNumber={phoneNumber}
          country={country}
          demoType={demoType}
          demoTypePreSelected={demoTypePreSelected}
          startNewSession={startNewSession}
          formLoadCounter={formLoadCounter}
          setSessionData={props.setSessionData}
        />
      </div>
      <Footer></Footer>
    </>
  );
}
