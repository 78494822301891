import axios, { ResponseType } from "axios";
import { baseUrl } from "../consts/baseUrl";
import * as jose from "jose";

export const getPDF = async (sessionId: string) => {
	const responseType: ResponseType = "arraybuffer";
	const config = {
		method: "get",
		url: `${baseUrl}/session/${sessionId}/generate-pdf`,
		responseType,
		headers: {
			Accept: "application/pdf",
			Authorization: `Bearer ${process.env.REACT_APP_BACKEND_SERVICE_KEY}`,
		},
	};
	return await axios(config);
};

export const savePDF = async (sessionId: string) => {
	// this.openModal(‘Loading…’) // open modal
	return getPDF(sessionId) // API call
		.then((response) => {
			const blob = new Blob([response.data], { type: "application/pdf" });
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(blob);
			link.download = `Replicant-Immersive-Demo-${sessionId}.pdf`;
			let downloadStarted = false;

			link.addEventListener("click", () => {
				downloadStarted = true;
			});

			link.click();

			if (downloadStarted) {
				return true;
			}
			//  this.closeModal() // close modal
		})
		.catch((err) => console.error);
};

export const generateToken = async (sessionId: string): Promise<string> => {
	// Encode shared JWT secret using HS256 algorithm
	const secret = new TextEncoder().encode(
		`${process.env.REACT_APP_JWT_SECRET}`
	);
	const alg = "HS256";
	// Sign new JWT token using scopes and session ID
	const joseToken = await new jose.SignJWT({
		scopes: ["create:call"],
		sessionId: `${sessionId}`,
	})
		// Set the protected header of JWT being created
		.setProtectedHeader({ alg })
		// Sign the JWT with the secret
		.sign(secret);
	return joseToken;
};
