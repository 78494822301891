const Intro = (props: any) => {
  return (
    <div>
      <p>
        Say 'hello' to the future of customer service. Follow these steps for an
        optimal experience.
      </p>
      <button onClick={props.nextStep}>Next</button>
    </div>
  );
};

export default Intro;
