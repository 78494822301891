import { SessionData } from "../../types/interfaces";
import "./SpeechBubble.css";
import ActionIcon from "../../assets/images/actionIcon.svg";

export default function SpeechBubble(props: {
  speaker: string;
  sessionData?: SessionData;
  transcript: string;
  isStarted?: boolean;
  isComplete?: boolean;
}) {
  const { speaker, sessionData, transcript, isStarted, isComplete } = props;

  return (
    <>
      {speaker === "bot" && transcript && (
        <>
          <p className="voiceTurn botTurn">{transcript}</p>
        </>
      )}
      {speaker === "user" && transcript && (
        <p className="voiceTurn userTurn">{transcript}</p>
      )}
      {speaker === "action" && transcript && (
        <div className="actionTurn">
          <img className="actionIcon" src={ActionIcon}></img>
          <p>{transcript}</p>
        </div>
      )}
    </>
  );
}
