import "./Header.css";
import { useEffect, useState } from "react";
import WhiteLogo from "./Logo/WhiteLogo";

export default function Header() {
  const [isVisible, setIsVisible] = useState(true);

  const listenToScroll = (element?: string) => {
    let heightToHideFrom = 20;
    let winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (element) {
      const container = document.querySelector(element);
      winScroll = container?.scrollTop!;
    }
    if (winScroll > heightToHideFrom) {
      isVisible && // to limit setting state only the first time
        setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", () => listenToScroll());
    const formContainer = document.querySelector("#formContainer");
    formContainer?.addEventListener("scroll", () =>
      listenToScroll("#formContainer")
    );
    const postCallContainer = document.querySelector("#postCallContainer");
    postCallContainer?.addEventListener("scroll", () =>
      listenToScroll("#postCallContainer")
    );
    return () => {
      window.removeEventListener("scroll", () => listenToScroll());
      formContainer?.removeEventListener("scroll", () =>
        listenToScroll("#formContainer")
      );
      postCallContainer?.removeEventListener("scroll", () =>
        listenToScroll("#postCallContainer")
      );
    };
  }, []);
  return (
    <>
      <div id="header" className={isVisible ? "visible" : "hidden"}>
        {/* <img width="206" height="47" src={whiteLogo} /> */}
        <a href="https://www.replicant.com">
          <WhiteLogo></WhiteLogo>
        </a>
      </div>
    </>
  );
}
