import "./App.css";
import Call from "./layouts/Call";
import PreCallContext from "./layouts/PreCallContext";
import PreCallForm from "./layouts/PreCallForm";
import useSessionData from "./hooks/useSessionData";
import PostCall from "./layouts/PostCall";
import { useEffect, useState } from "react";
import Modal from "./components/Modal";

function App() {
	const { sessionData, setSessionData, startNewSession } = useSessionData();
	const {
		firstName,
		lastName,
		email,
		phoneNumber,
		country,
		demoType,
		demoTypePreSelected,
	} = sessionData;
	const {
		currentTurn,
		fullTranscript,
		callTime,
		callProgress,
		callStarted,
		callComplete,
		boothId,
		isRateLimitExceeded,
	} = sessionData.call;
	const {
		experienceStarted,
		experienceLoaded,
		sessionValid,
		progressBar,
		isMultipleSessions,
		formLoadCounter,
		refreshedDuringCall,
	} = sessionData.session;

	const view: string = experienceStarted ? "context" : "form";
	const [mobile, setMobile] = useState(false);

	// Use this function to detect when mobile device and display different page
	useEffect(() => {
		const detectMobile = () => {
			if (
				navigator.userAgent.match(/Android/i) ||
				navigator.userAgent.match(/webOS/i) ||
				navigator.userAgent.match(/iPhone/i) ||
				navigator.userAgent.match(/iPod/i) ||
				navigator.userAgent.match(/BlackBerry/i) ||
				navigator.userAgent.match(/Windows Phone/i)
			) {
				setMobile(true);
			}
		};

		detectMobile();

		// Use this function to adjust for the size of an address bar on mobile devices
		// Function to set the --vh custom property
		const setVh = () => {
			document.documentElement.style.setProperty(
				"--vh",
				`${window.innerHeight * 0.01}px`
			);
		};

		// Set the --vh custom property initially
		setVh();

		// Update the --vh custom property whenever the window is resized
		window.addEventListener("resize", setVh);

		// Clean up the event listener when the component is unmounted
		return () => {
			window.removeEventListener("resize", setVh);
		};
	}, []);

	const [scrollPosition, setScrollPosition] = useState(0);

	const listenToScroll = (element?: string) => {
		let winScroll =
			document.body.scrollTop || document.documentElement.scrollTop;

		if (element) {
			const container = document.querySelector(element);
			winScroll = container?.scrollTop!;
		}
		setScrollPosition(winScroll);
	};

	useEffect(() => {
		const handleWindowScroll = () => listenToScroll();
		const handleFormScroll = () => listenToScroll("#formContainer");
		const handlePostCallScroll = () => listenToScroll("#postCallContainer");

		window.addEventListener("scroll", handleWindowScroll);

		const formContainer = document.querySelector("#formContainer");
		formContainer?.addEventListener("scroll", handleFormScroll);

		const postCallContainer = document.querySelector("#postCallContainer");
		postCallContainer?.addEventListener("scroll", handlePostCallScroll);

		return () => {
			window.removeEventListener("scroll", handleWindowScroll);
			formContainer?.removeEventListener("scroll", handleFormScroll);
			postCallContainer?.removeEventListener("scroll", handlePostCallScroll);
		};
	}, [callComplete]);

	// TODO: Update the conditional rendering for the values we'll receive when using an actual dynamic call
	return (
		<div
			className={`pageContainer`}
			style={{
				height: `calc(var(--vh, 1vh) * 100)`,
			}}
		>
			<>
				{isMultipleSessions && <Modal view={"multipleSessions"}></Modal>}
				{isRateLimitExceeded && <Modal view={"rateLimitExceeded"}></Modal>}
				{/* Show the form page if the experience hasn't started and the session ID is not valid */}
				{!experienceStarted && !sessionValid && (
					<PreCallForm
						view={view}
						startNewSession={startNewSession}
						boothId={boothId}
						firstName={firstName}
						lastName={lastName}
						email={email}
						phoneNumber={phoneNumber}
						country={country}
						demoType={demoType}
						demoTypePreSelected={demoTypePreSelected}
						formLoadCounter={formLoadCounter}
						setSessionData={setSessionData}
					></PreCallForm>
				)}
				{/* Show the context page if the experience has started but is not loaded / call hasn't started */}
				{!!experienceStarted && !experienceLoaded && !callStarted && (
					<PreCallContext
						view={view}
						progressBar={progressBar}
						country={country}
						demoType={demoType}
						mobile={mobile}
					></PreCallContext>
				)}
				{/* Show the call transcripts if session is valid, call has started/experience has loaded and call is not complete */}
				{!!sessionValid &&
					(!!callStarted || !!experienceLoaded) &&
					!callComplete && (
						<Call
							currentTurn={currentTurn}
							fullTranscript={fullTranscript}
							callTime={callTime}
							callProgress={callProgress}
							demoType={demoType}
							mobile={mobile}
						></Call>
					)}
				{/* Show post-call page if session is still valid and call is complete */}
				{!!sessionValid && !!callComplete && (
					<PostCall
						startNewSession={startNewSession}
						firstName={firstName}
						lastName={lastName}
						email={email}
						phoneNumber={phoneNumber}
						setSessionData={setSessionData}
						refreshedDuringCall={refreshedDuringCall}
					></PostCall>
				)}
			</>
		</div>
	);
}

export default App;
