const DemoType = (props: any) => {
  return (
    <div>
      <p>What type of scenario would you like to experience on this call?</p>
      <div className="inputContainer">
        <select
          id="demoType"
          name="demoType"
          value={props.demoType}
          onChange={props.onChange}
          required
        >
          <option selected value={"EMR"}>
            Scheduling an eye appointment
          </option>
          <option value={"ERS"}>Requesting roadside assistance</option>
        </select>
      </div>
      <button id="demoTypeButton" onClick={props.nextStep}>
        Next
      </button>
    </div>
  );
};

export default DemoType;
