const Name = (props: any) => {
  const nameValidation = (): boolean => {
    if (
      (!props.firstName || props.firstName.trim() === "") &&
      (!props.lastName || props.lastName.trim() === "")
    ) {
      props.setErrors((prev: any) => {
        return { ...prev, firstName: true, lastName: true };
      });
      return false;
    } else if (!props.firstName || props.firstName.trim() === "") {
      props.setErrors((prev: any) => {
        return { ...prev, firstName: true };
      });
      return false;
    } else if (!props.lastName || props.lastName.trim() === "") {
      props.setErrors((prev: any) => {
        return { ...prev, lastName: true };
      });
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <p>How should our AI agent greet you?</p>
      <div className="doubleInputs">
        <div className="inputContainer">
          <input
            id="firstName"
            type="text"
            placeholder="First Name"
            name="firstName"
            value={props.firstName}
            onChange={props.onChange}
            onKeyDown={(e) =>
              props.handleEnter(e, nameValidation, props.nextStep)
            }
            required
          />
          {props.errors["firstName"] && props.submitAttempted["firstName"] && (
            <div className="tooltip">{props.errorMessage}</div>
          )}
        </div>
        <div className="inputContainer">
          <input
            type="text"
            id="lastName"
            placeholder="Last Name"
            name="lastName"
            value={props.lastName}
            onChange={props.onChange}
            onKeyDown={(e) =>
              props.handleEnter(e, nameValidation, props.nextStep)
            }
            required
          />
          {props.errors["lastName"] && props.submitAttempted["lastName"] && (
            <div className="tooltip">{props.errorMessage}</div>
          )}
        </div>
      </div>
      <button
        id="nameButton"
        onClick={(e) => props.nextStepCheck(e, nameValidation, props.nextStep)}
      >
        Next
      </button>
    </>
  );
};

export default Name;
