import { FormEvent, useEffect, useState } from "react";
import StepWizard from "react-step-wizard";
import "animate.css";
import Intro from "./Intro";
import Name from "./Name";
import Email from "./Email";
import PhoneNumber from "./PhoneNumber";
import "./Form.css";
import { SessionData } from "../../types/interfaces";
import DemoType from "./DemoType";

export default function Form(props: {
  startNewSession: (
    phoneNumber?: string,
    email?: string,
    name?: string
  ) => Promise<
    | {
        sessionId: string;
        country: string;
      }
    | { isValidEmail: boolean; isValidNumber: boolean }
  >;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  country: string;
  demoType: string;
  demoTypePreSelected: boolean;
  formLoadCounter: number;
  setSessionData: any;
}) {
  const {
    startNewSession,
    firstName,
    lastName,
    email,
    phoneNumber,
    country,
    demoType,
    demoTypePreSelected,
    formLoadCounter,
    setSessionData,
  } = props;

  const [form, setForm] = useState({
    scriptLoaded: false,
    formSubmitted: false,
  });

  const marketo = {
    baseUrl: "https://explore.replicant.com",
    munchkinId: "568-XCB-052",
    formId: "1247",
  };

  const { baseUrl, munchkinId, formId } = marketo;

  useEffect(() => {
    function appendScript(baseUrl: string) {
      // If the form is already on the page and we are not trying to load it again, return
      if (window.MktoForms2 && formLoadCounter === 1) return;

      const script = document.createElement("script");
      script.src = `${baseUrl}/js/forms2/js/forms2.min.js`;
      script.onload = () =>
        window.MktoForms2
          ? setForm((prev) => {
              return { ...prev, scriptLoaded: true };
            })
          : null;
      document.head.appendChild(script);
    }

    // If the form script is loaded, create the form on the page
    if (form.scriptLoaded) {
      window.MktoForms2.loadForm(
        baseUrl,
        munchkinId,
        formId,
        function (mktoForm: any) {
          // Pre-fill the values if they exist
          if (firstName && lastName && email && phoneNumber) {
            // Save the form data to a local variable
            // Pre-fill form except for phone number and demo type
            const formData = {
              FirstName: firstName,
              LastName: lastName,
              Email: email,
            };
            // Set the form values
            mktoForm.setValues(formData);
          }
        }
      );
      return;
    }
    // Adds the script element to the page
    appendScript(baseUrl);
    // Run this effect on page load and when the formLoadCounter changes
  }, [form.scriptLoaded, form.formSubmitted, formLoadCounter]);

  const custom = {
    enterRight: "animate__animated animate__fadeIn",
    enterLeft: "animate__animated animate__fadeIn",
    intro: "animate__animated animate__fadeIn",
  };

  interface Errors {
    [key: string]: boolean;
    firstName: boolean;
    lastName: boolean;
    email: boolean;
    phoneNumber: boolean;
    demoType: boolean;
  }

  const [errors, setErrors] = useState<Errors>({
    firstName: false,
    lastName: false,
    email: false,
    phoneNumber: false,
    demoType: false,
  });

  const [submitAttempted, setSubmitAttempted] = useState<Errors>({
    firstName: false,
    lastName: false,
    email: false,
    phoneNumber: false,
    demoType: false,
  });

  const [successfulFormSubmit, setSuccessfulFormSubmit] = useState(false);

  // Update the session data in state when the form values change
  const onChange = (e: FormEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.currentTarget;
    setErrors((prev: Errors) => {
      return { ...prev, [name]: false };
    });
    setSessionData((prev: SessionData): SessionData => {
      return {
        ...prev,
        // Set the call data in state to the value of the input from the form
        [name]: name === "phoneNumber" ? value.replace(/[^0-9]/g, "") : value,
      };
    });
  };

  const handleEnter = (
    event: React.KeyboardEvent<HTMLInputElement>,
    validationFunction: any,
    nextStep: any
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      nextStepCheck(event, validationFunction, nextStep);
    }
  };

  const nextStepCheck = (
    e: FormEvent<HTMLInputElement>,
    validationFunction: any,
    nextStep: any
  ) => {
    const { id, value } = e.currentTarget;
    const name = id.replace("Button", "");
    // Set submit attempted to true so that the tooltip will show
    if (name === "name") {
      setSubmitAttempted((prev) => {
        return { ...prev, firstName: true, lastName: true };
      });
    } else {
      setSubmitAttempted((prev) => {
        return { ...prev, [name]: true };
      });
    }

    const inputValid = validationFunction();
    if (inputValid) {
      // If we're not on the last step, go to the next step
      if (name !== "phoneNumber") {
        nextStep();
      } else {
        // If we're on the last step, set formSubmitted to true
        setForm((prev) => {
          return { ...prev, formSubmitted: true };
        });
        setSuccessfulFormSubmit(true);
        window.MktoForms2.getForm(formId)
          .setValues({
            FirstName: firstName,
            LastName: lastName,
            Email: email,
            Phone: phoneNumber,
            immersiveUseCase: demoType === "EMR" ? "Scheduling" : demoType,
          })
          .onSuccess(
            (
              values: {
                FirstName: string;
                LastName: string;
                Email: string;
                Phone: string;
                immersiveUseCase: string;
              },
              followUpUrl: string | null
            ) => {
              // Create the session if the form successfully submits
              const formName = `${values.FirstName} ${values.LastName}`;
              const formEmail = values.Email;
              const formPhone = values.Phone;

              startNewSession(formName, formEmail, formPhone);
              // Prevents the page from re-loading
              return false;
            }
          )
          .submit();
      }
    } else {
      // Will only be null when a competitor email - this prevents tooltip from showing up
      if (inputValid === null) {
        return;
      }
      setErrors((prev) => {
        return { ...prev, [name]: true };
      });
    }
  };

  return (
    <>
      {/* If demo type is not preselected, display step for with demoType step */}
      {!demoTypePreSelected && (
        <>
          <StepWizard transitions={custom} className={"formSteps"}>
            <Intro className={"formSteps"}></Intro>
            <Name
              className={"formSteps"}
              firstName={firstName}
              lastName={lastName}
              onChange={onChange}
              nextStepCheck={nextStepCheck}
              errors={errors}
              errorMessage={"This field is required."}
              submitAttempted={submitAttempted}
              handleEnter={handleEnter}
              setErrors={setErrors}
            ></Name>
            <DemoType
              className={"formSteps"}
              demoType={demoType}
              onChange={onChange}
              submitAttempted={submitAttempted}
            ></DemoType>

            <Email
              className={"formSteps"}
              email={email}
              onChange={onChange}
              nextStepCheck={nextStepCheck}
              errors={errors}
              errorMessage={"This field is required."}
              submitAttempted={submitAttempted}
              handleEnter={handleEnter}
            ></Email>
            <PhoneNumber
              className={"formSteps"}
              phoneNumber={phoneNumber}
              onChange={onChange}
              nextStepCheck={nextStepCheck}
              errors={errors}
              errorMessage={"This field is required."}
              submitAttempted={submitAttempted}
              firstName={firstName}
              lastName={lastName}
              email={email}
              country={country}
              handleEnter={handleEnter}
              successfulFormSubmit={successfulFormSubmit}
            ></PhoneNumber>
          </StepWizard>
        </>
      )}
      {/* If demo type is preselected, display step form without demoType step */}
      {!!demoTypePreSelected && (
        <>
          <StepWizard transitions={custom} className={"formSteps"}>
            <Intro className={"formSteps"}></Intro>
            <Name
              className={"formSteps"}
              firstName={firstName}
              lastName={lastName}
              onChange={onChange}
              nextStepCheck={nextStepCheck}
              errors={errors}
              errorMessage={"This field is required."}
              submitAttempted={submitAttempted}
              handleEnter={handleEnter}
              setErrors={setErrors}
            ></Name>
            <Email
              className={"formSteps"}
              email={email}
              onChange={onChange}
              nextStepCheck={nextStepCheck}
              errors={errors}
              errorMessage={"This field is required."}
              submitAttempted={submitAttempted}
              handleEnter={handleEnter}
            ></Email>
            <PhoneNumber
              className={"formSteps"}
              phoneNumber={phoneNumber}
              onChange={onChange}
              nextStepCheck={nextStepCheck}
              errors={errors}
              errorMessage={"This field is required."}
              submitAttempted={submitAttempted}
              firstName={firstName}
              lastName={lastName}
              email={email}
              handleEnter={handleEnter}
              successfulFormSubmit={successfulFormSubmit}
            ></PhoneNumber>
          </StepWizard>
        </>
      )}
      <form id={`mktoForm_${formId}`} style={{ display: "none" }}></form>
    </>
  );
}
