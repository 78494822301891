import SpeechBubble from "../components/SpeechBubble";
import { TranscriptTurn } from "../types/interfaces";
import "./Call.css";
import { useEffect, useRef } from "react";
import ReplicantLogo from "../assets/images/Replicant_White.svg";
import Soundwave from "../assets/images/soundWave.svg";
import circle2 from "../assets/images/circle2.svg";
import circle3 from "../assets/images/circle3.svg";
import circle4 from "../assets/images/circle4.svg";
import circle5 from "../assets/images/circle5.svg";
import circle6 from "../assets/images/circle6.svg";
import logos from "../assets/images/logos.svg";
import progressCircleBlue from "../assets/images/progressCircleBlue.svg";
import LoadingBubble from "../components/LoadingBubble";
import CallHeader from "../components/CallHeader";
import { callProgressMarkers } from "../consts/callProgress";

export default function Call(props: {
  currentTurn: number | null;
  fullTranscript: TranscriptTurn[];
  callTime: number;
  callProgress: number;
  demoType: string;
  mobile: boolean;
}) {
  const {
    currentTurn,
    fullTranscript,
    callTime,
    callProgress,
    demoType,
    mobile,
  } = props;

  const pad = (val: number): string => {
    let valString = val + "";
    if (valString.length < 2) {
      return "0" + valString;
    } else {
      return valString;
    }
  };
  const counterDisplay = `${Math.trunc(callTime / 60)}:${pad(callTime % 60)}`;
  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      block: "end",
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [fullTranscript]);

  const transcript = fullTranscript.map((turn: TranscriptTurn) => {
    let transcript: string = "";
    let speechBubble = null;
    if (turn.type === "action") {
      transcript = `${turn.label}:\n${turn.transcript}`;
    } else {
      transcript = turn.transcript ?? "";
    }
    if (currentTurn === turn.Number) {
      let id = `${turn.type}LastMessage`;
      speechBubble = (
        <div ref={messagesEndRef} id={id} key={turn.Number}>
          {turn.type !== "listen" && (
            <SpeechBubble
              speaker={turn.type}
              transcript={transcript}
            ></SpeechBubble>
          )}
          {turn.type === "listen" && <LoadingBubble></LoadingBubble>}
        </div>
      );
    } else {
      speechBubble = (
        <SpeechBubble
          key={turn.Number}
          speaker={turn.type}
          transcript={transcript}
        ></SpeechBubble>
      );
    }
    return speechBubble;
  });

  const currentCallProgressStage =
    callProgressMarkers.callProgress[demoType][Math.floor(callProgress / 20)];

  return (
    <>
      <CallHeader mobile={mobile} counterDisplay={counterDisplay}></CallHeader>
      <div id="callContainer">
        <div id="mainCallContainer">
          <div id="callProgressContainer">
            <div id="callProgressHeader">
              <p id="progressTitle">Journey Progress</p>
              <p id="progressTimer">
                {mobile ? currentCallProgressStage : counterDisplay}
              </p>
            </div>
            <div id="callProgressSection">
              <img
                src={progressCircleBlue}
                id="circleOne"
                className="callProgressCircle"
              ></img>
              <img
                src={callProgress >= 20 ? progressCircleBlue : circle2}
                id="circleTwo"
                className="callProgressCircle"
              ></img>
              <img
                src={callProgress >= 40 ? progressCircleBlue : circle3}
                id="circleThree"
                className="callProgressCircle"
              ></img>
              <img
                src={callProgress >= 60 ? progressCircleBlue : circle4}
                id="circleFour"
                className="callProgressCircle"
              ></img>
              <img
                src={callProgress >= 80 ? progressCircleBlue : circle5}
                id="circleFive"
                className="callProgressCircle"
              ></img>
              <img
                src={callProgress >= 100 ? progressCircleBlue : circle6}
                id="circleSix"
                className="callProgressCircle"
              ></img>
              <div id="callProgressBar">
                <span
                  id="callProgress"
                  style={{ width: `${callProgress}%` }}
                ></span>
              </div>
              <div id="callProgressLabels">
                <div className="callProgressLabel" id="collectAddress">
                  {callProgressMarkers.callProgress[demoType][0]}
                </div>
                <div className="callProgressLabel" id="createPatient">
                  {callProgressMarkers.callProgress[demoType][1]}
                </div>
                <div className="callProgressLabel" id="findOffice">
                  {callProgressMarkers.callProgress[demoType][2]}
                </div>
                <div className="callProgressLabel" id="collectDate">
                  {callProgressMarkers.callProgress[demoType][3]}
                </div>
                <div className="callProgressLabel" id="bookAppointment">
                  {callProgressMarkers.callProgress[demoType][4]}
                </div>
                <div className="callProgressLabel" id="endCall">
                  {callProgressMarkers.callProgress[demoType][5]}
                </div>
              </div>
            </div>
          </div>
          <div id="transcriptContainer">{transcript}</div>
        </div>
        <div id="sidebar">
          <div id="sidebarTop">
            <img id="sidebarLogo" src={ReplicantLogo}></img>
            <p id="sidebarText">
              {
                "You are now speaking with Replicant's Thinking Machine.\n\nHang up to end the call at any time."
              }
            </p>
            <img id="soundwave" src={Soundwave}></img>
            <div id="divider"> </div>
          </div>
          <div id="sidebarBottom">
            <p id="sidebarBottomText">{"Trusted By:"}</p>
            <img id="logos" src={logos}></img>
          </div>
        </div>
      </div>
    </>
  );
}
