import PreCallLogo from "../components/Logo/PreCallLogo";
import TextBox from "../components/TextBox";
import "./PreCallContext.css";
import logoPatternOne from "../assets/images/logoPattern1.svg";
import logoPatternTwo from "../assets/images/logoPattern2.svg";
import logoPatternThree from "../assets/images/logoPattern3.svg";
import logoPatternFour from "../assets/images/logoPattern4.svg";
import logoPatternFive from "../assets/images/logoPattern5.svg";

export default function PreCallContext(props: {
  view: string;
  progressBar: number;
  country: string;
  demoType: string;
  mobile: boolean;
}) {
  let { view, progressBar, country, demoType, mobile } = props;
  let width: number;

  if (progressBar > 50) {
    view = "contextTwo";
  }

  if (progressBar > 100) {
    width = 0;
  } else {
    width = 100 - progressBar;
  }

  return (
    <>
      <div id="contextContainer">
        <PreCallLogo></PreCallLogo>
        <TextBox
          view={view}
          country={country}
          demoType={demoType}
          mobile={mobile}
        ></TextBox>
        <div id="progressBar">
          <span id="progress" style={{ width: `${width}%` }}></span>
        </div>
      </div>
    </>
  );
}
