import { SessionData } from "../types/interfaces";
import "./Modal.css";

export default function Modal(props: {
  view: "multipleSessions" | "rateLimitExceeded" | "newCall";
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  startNewSession?: any;
  setSessionData?: any;
  setNewCall?: any;
}) {
  const {
    view,
    firstName,
    lastName,
    email,
    phoneNumber,
    startNewSession,
    setSessionData,
    setNewCall,
  } = props;
  const handleClick = () => {
    // If the rate limit is exceeded or a new call is requested, clear the local storage of the session ID just created
    if (view !== "multipleSessions") {
      localStorage.clear();
    }

    // Reset session data and pre-fill the form if someone is starting a new call
    if (view === "newCall") {
      // If coming from a post-call page with a URL specifying demoType, manually set the demoType and demoTypePreSelected
      const url = window.location.href;
      let demoType = "EMR";
      let demoTypePreSelected = false;
      if (url.includes("/ers")) {
        demoType = "ERS";
        demoTypePreSelected = true;
      } else if (url.includes("/scheduling")) {
        // Don't need to set demoType since EMR is default
        demoTypePreSelected = true;
      }
      // Reset session data to default values apart from the formLoadCounter
      setSessionData((prev: SessionData) => {
        return {
          ...prev,
          firstName,
          lastName,
          email: email,
          country: "",
          phoneNumber: phoneNumber,
          demoType,
          demoTypePreSelected,
          session: {
            sessionCreated: false,
            experienceStarted: false,
            progressBar: 0,
            experienceLoaded: false,
            sessionId: "",
            sessionValid: false,
            isMultipleSessions: false,
            // Iterate the formLoadCounter so the form will re-render
            formLoadCounter: prev.session.formLoadCounter + 1,
          },
          call: {
            callStarted: false,
            callTime: 0,
            callProgress: 0,
            callComplete: false,
            currentTurn: 0,
            boothId: "",
            fullTranscript: [],
            internalTestCall: false,
            isRateLimitExceeded: false,
          },
        };
      });
    } else {
      // Reload the page in other cases
      window.location.reload();
    }
  };

  const closeModal = () => {
    // If the view is newCall, set newCall to false to close the modal
    if (view === "newCall") {
      setNewCall(false);
    } else {
      // If the view is rateLimitExceeded, clear the local storage of the session ID just created
      view === "rateLimitExceeded" && localStorage.clear();
      // Reload the page for both views
      window.location.reload();
    }
  };

  return (
    <>
      <div id="modalBackdrop" onClick={closeModal}></div>
      <div id="modal" className="modal">
        <h2 id="modalHeader" className="title">
          {view === "newCall" ? "Confirm Call Details" : "Sorry."}
        </h2>
        <p id="modalMessage" className="textbox">
          {view === "multipleSessions"
            ? `Our records show an ongoing call with the phone number you provided.\n\nPlease try again in 15 minutes.\n`
            : view === "rateLimitExceeded"
            ? `This demo is a little too popular right now.\n\nPlease try again in one minute.\n`
            : `Would you like to use the same phone number and call scenario as your previous call?\n\n`}
        </p>

        <div id="buttonContainer">
          <button
            id={view === "newCall" ? "noButton" : "refreshButton"}
            onClick={handleClick}
          >
            {view === "newCall" ? "EDIT INFO" : "DISMISS"}
          </button>
          {/* Show the other button if new call requested */}
          {view === "newCall" && (
            <button id="refreshButton" onClick={() => startNewSession()}>
              CALL AGAIN
            </button>
          )}
        </div>
      </div>
    </>
  );
}
