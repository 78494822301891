import { competitorDomains, freeDomains } from "../../consts/restrictedDomains";

const Email = (props: any) => {
  let emailErrorMessage = freeDomains
    .concat(competitorDomains)
    .some((domain) => props.email.includes(domain))
    ? "Please use a work email."
    : props.errorMessage;

  const emailValidation = (): boolean | null => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isFreeDomain = freeDomains.some((domain) =>
      props.email.includes(domain)
    );
    const isCompetitorDomain = competitorDomains.some((domain) =>
      props.email.includes(domain)
    );
    if (!!props.email) {
      // TODO: If competitor domain redirect to careers page
      if (isCompetitorDomain) {
        window.location.href = "https://www.replicant.ai/careers";
        return null;
      } else {
        return regex.test(props.email) && !isFreeDomain;
      }
    } else {
      return false;
    }
  };

  return (
    <div>
      <p>What email address should we send the post-call summary to?</p>
      <div className="inputContainer">
        <input
          id="email"
          type="email"
          placeholder="Email"
          name="email"
          value={props.email}
          onChange={props.onChange}
          onKeyDown={(e) =>
            props.handleEnter(e, emailValidation, props.nextStep)
          }
          required
        />
        {/* If the email doesn't pass the emailValidation or it hasn't been submitted */}
        {/* TODO: THIS TOOLTIP IS NOT SHOWING UP, BUT VALIDATION IS WORKING */}
        {!!props.errors["email"] && !!props.submitAttempted["email"] && (
          <div className="tooltip">{emailErrorMessage}</div>
        )}
      </div>
      <button
        id="emailButton"
        onClick={(e) => {
          props.nextStepCheck(e, emailValidation, props.nextStep);
        }}
      >
        Next
      </button>
    </div>
  );
};

export default Email;
