import "./Footer.css";

export default function Footer() {
  return (
    <footer>
      <div id="footerBottom">
        <div id="row">
          <div id="copyrights">
            Copyright © {new Date().getFullYear()} Replicant, Inc, All Rights
            Reserved{" "}
          </div>
          <div id="footerSecNav">
            <ul id="menu-footer-bottom">
              <li id="menu-item-3147">
                <a href="https://www.replicant.com/legal/terms-of-service/">
                  Terms of Service
                </a>
              </li>
              <li id="menu-item-3148">
                <a href="https://www.replicant.com/legal/privacy-policy/">
                  Privacy Policy
                </a>
              </li>
              <li id="menu-item-3149">
                <a href="https://www.replicant.com/legal/bug-bounty-program/">
                  Bug Bounty Program
                </a>
              </li>
            </ul>{" "}
          </div>
        </div>
      </div>
    </footer>
  );
}
