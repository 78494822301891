export type CallProgressPoints = {
  0: string;
  20: string;
  40: string;
  60: string;
  80: string;
  100: string;
};

export type CallProgressMarkers = {
  actionTranscripts: {
    [key: string]: {
      [key: number]: string;
    };
    EMR: CallProgressPoints;
    ERS: CallProgressPoints;
  };
  callProgress: {
    [key: string]: string[];
    EMR: string[];
    ERS: string[];
  };
};

export const callProgressMarkers: CallProgressMarkers = {
  actionTranscripts: {
    EMR: {
      0: "Create Patient",
      20: "Google Places Result Found",
      40: "Find Office",
      60: "Collect Date",
      80: "EHR Appointment Created",
      100: "EHR Ticket Closed",
    },
    ERS: {
      0: "Create Call",
      20: "Collect Vehicle Location",
      40: "Collect Vehicle Details",
      60: "Google Places Result Found",
      80: "Create Service Request",
      100: "Call End",
    },
  },
  callProgress: {
    EMR: [
      "Create Patient",
      "Collect Address",
      "Find Office",
      "Collect Date",
      "Book Appointment",
      "Call End",
    ],
    ERS: [
      "Create Call",
      "Vehicle Location",
      "Vehicle Details",
      "Tow Destination",
      "Create Service Request",
      "Call End",
    ],
  },
};
