import "./PostCall.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useEffect, useState } from "react";
import Modal from "../components/Modal";

export default function PostCall(props: {
  startNewSession: any;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  setSessionData: any;
  refreshedDuringCall: boolean;
}) {
  const {
    startNewSession,
    firstName,
    lastName,
    email,
    phoneNumber,
    setSessionData,
    refreshedDuringCall,
  } = props;

  const [newCall, setNewCall] = useState(false);
  const [revisit, setRevisit] = useState(false);

  useEffect(() => {
    // If there's no user data in state, the user is revisiting the page
    if (!firstName && !lastName) {
      setRevisit(true);
    }
  }, []);

  return (
    <>
      <Header></Header>
      {newCall && (
        <Modal
          view={"newCall"}
          firstName={firstName}
          lastName={lastName}
          email={email}
          phoneNumber={phoneNumber}
          startNewSession={startNewSession}
          setSessionData={setSessionData}
          setNewCall={setNewCall}
        ></Modal>
      )}
      <div id="postCallContainer">
        {/* <img id="postCallLogoPatternTwo" src={postCallLogoPatternTwo}></img> */}
        <div id="postCallIntro">
          {/* <img id="postCallLogoPatternOne" src={postCallLogoPatternOne}></img> */}
          <p id="postCallIntroTitle" className="title">
            {/* Show welcome back if re-vist and didn't refresh during call */}
            {revisit && !refreshedDuringCall ? "Welcome Back!" : "Thank You!"}
          </p>
          <p id="postCallTextBox" className="textbox">
            {revisit && !refreshedDuringCall
              ? "Ready to explore more, or discuss your experience?"
              : "You will receive an email with your post-call summary."}
          </p>
          <div id="buttonsContainer">
            <a
              href={
                "https://www.replicant.com/immersive-demo-thank-you/?utm_source=Website&utm_medium=Direct&utm_campaign=DR_ImmersiveDemoLPRequestFUP"
              }
              className={`downloadButton`}
            >
              {"REQUEST A MEETING"}
            </a>
            <div
              id="tryAnotherCallButton"
              className={`downloadButton`}
              onClick={() => setNewCall(true)}
            >
              {"TRY ANOTHER CALL"}
            </div>
          </div>
        </div>
        <div id="postCallBox">
          <img
            id="thankYouImage"
            width={505}
            height={552}
            src={
              "https://www.replicant.com/wp-content/uploads/2022/09/Thank-You-2.png"
            }
          ></img>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
