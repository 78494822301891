import WhiteLogo from "./Logo/WhiteLogo";
import "./CallHeader.css";

export default function CallHeader(props: {
  mobile: boolean;
  counterDisplay: string;
}) {
  const { mobile, counterDisplay } = props;
  return (
    <>
      <div id="callHeader">
        <a href="https://www.replicant.com">
          <WhiteLogo view={"call"}></WhiteLogo>
        </a>
        {mobile && <p id="mobileCounter">{counterDisplay}</p>}
      </div>
    </>
  );
}
